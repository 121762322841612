import {Component} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {VasApiReport} from './vas-api-report';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public message: string;
  public phoneNumber: string;

  public processing = false;
  public success = false;
  public error = false;

  public response;
  public page: 'form' | 'history' = 'form';

  public userId?: string;
  public password?: string;
  public report?: VasApiReport;

  public constructor(
    private http: HttpClient,
  ) {
  }

  public onSubmitForm(): void {
    this.success = false;
    this.error = false;
    this.processing = true;
    setTimeout(() => {
      this.http.get(
        'https://e3.exetree.eu/vas-service/rest/drainsms/add_record/'
        + encodeURIComponent(this.phoneNumber) + '/' + encodeURIComponent(this.message)
      )
        .subscribe((messages) => {
          if (!this.processing) {
            return;
          }
          this.success = true;
          this.processing = false;
          this.response = messages;
          console.log(messages);
        }, (error) => {
          this.error = true;
          console.log(error);
          this.processing = false;
        });
    }, 2_000);
  }

  public onSubmitHistory(): void {
    this.success = false;
    this.error = false;
    this.processing = true;
    setTimeout(() => {
      this.http.get(
        'https://e3.exetree.eu/vas-service/rest/drainsms/history/'
        + encodeURIComponent(this.userId) + '/' + encodeURIComponent(this.password)
      )
        .subscribe((report: VasApiReport) => {
          if (!this.processing) {
            return;
          }
          this.success = true;
          this.processing = false;
          this.report = report;
          this.report.records.sort((a, b) => Date.parse(b.workFrom) - Date.parse(a.workFrom));
          console.log(report);
        }, (error) => {
          this.error = true;
          console.log(error);
          this.processing = false;
        });
    }, 2_000);
  }

  public open(page: 'form' | 'history'): boolean {
    this.processing = false;
    this.success = false;
    this.error = false;
    this.page = page;
    this.response = undefined;
    return false;
  }
}
